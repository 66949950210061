<template>
  <div class="simulation">
    <edited-menu
      :id="menuID"
      :config="config"
      :locale="dtouchSimulationLocale"
      :refreshSortContent="refreshSortContent"
      :onEditItem="onEditItem"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import EditedMenu from './editedMenu/Index'
export default {
  name: 'Simulation',
  components: {
    EditedMenu,
  },
  props: {
    config: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    parentID: {
      type: String,
      default: null,
    },
    isMenu: {
      type: Boolean,
      default: true,
    },
    refreshSortContent: {
      type: Object,
      default: null,
    },
    onEditItem: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    menuID: null,
  }),
  computed: {
    ...mapState('app',['dtouchSimulationLocale']),
  },
  mounted () {
    this.menuID = this.parentID ? this.parentID : this.id
  },
  watch: {
    id () {
      this.menuID = this.parentID ? this.parentID : this.id
    },
  },
}
</script>

